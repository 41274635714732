import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { END_POINT } from "../../utils/index";
import { clearLoginState } from "../users/usersSlice";
import { getRfqSuccess } from "./rfqSlice";
import * as actionSnackBar from "../snackbar/action";
const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export let rfqPostTradeSlice = createSlice({
  name: "rfqPostTradeSlice",
  initialState: {
    thereIsConfo: false,
    rfqObject: null,
    makorHSBCClient: [],
    makorHSBCMarketMakers: [],
    allMakorHSBCClientAndMarketMakers: [],
    makorMarketMakers: [],
    makorClients: [],
    oscarGrussMarketMakers: [],
    oscarGrussClients: [],
    currentMarketMaker: "",
    currentClient: [],
    currentPb: "",
    marketMaker: "",
    trader: "",
    traderNote: "",
    dfMids: "",
    confoInitial: "",
    marketMakerNeedsConfo: false,
    split: false,
    isSplitted: false,
    splitManner: "percentage",
    postTradeSplitNumber: 2,
    postTradeSplitArrValues: [],
    postTradesSplitArrPmValues: [],
    postTradeAmountLeftToSplit: "",
    currentLegsNotional: [],
    currentDeltasNotional: [],
    currentNearNotional: 0,
    currentFarNotional: 0,
    postTradeNearNotionalLeftToSplit: 0,
    postTradeFarNotionalLeftToSplit: 0,
    postTradeAmountLeftToNotionalSplit: [],
    postTradeAmountLeftToDeltaNotionalSplit: [],
    toEmailAddresses: [],
    ccEmailAddresses: [],
    singleConfo: false,
    postTradeSplitArrPreValues: [],
    sd: false,
    pba: false,
    startSendEmailStatus: false,
  },
  reducers: {
    resetPostTradeSplitArrPreValues: (state) => {
      state.postTradeSplitArrPreValues = [];
    },
    setPostTradeSplitArrPreValues: (state, action) => {
      state.postTradeSplitArrPreValues = action.payload;
    },
    setThereIsConfo: (state, action) => {
      state.thereIsConfo = action.payload;
    },
    setIsSplitted: (state, action) => {
      state.isSplitted = action.payload;
    },
    setPostTradeAmountLeftToSplit: (state, action) => {
      state.postTradeAmountLeftToSplit = action.payload;
    },
    setCurrentLegsNotional: (state, action) => {
      state.currentLegsNotional = action.payload;
    },
    setCurrentDeltasNotional: (state, action) => {
      state.currentDeltasNotional = action.payload;
    },
    setCurrentNearNotional: (state, action) => {
      state.currentNearNotional = action.payload;
    },
    setCurrentFarNotional: (state, action) => {
      state.currentFarNotional = action.payload;
    },
    setPostTradeAmountLeftToNotionalSplit: (state, action) => {
      state.postTradeAmountLeftToNotionalSplit = action.payload;
    },
    postTradeAmountLeftToDeltaNotionalSplit: (state, action) => {
      state.postTradeAmountLeftToDeltaNotionalSplit = action.payload;
    },
    postTradeNearNotionalLeftToSplit: (state, action) => {
      state.postTradeNearNotionalLeftToSplit = action.payload;
    },
    postTradeFarNotionalLeftToSplit: (state, action) => {
      state.postTradeFarNotionalLeftToSplit = action.payload;
    },
    resetPostTradeStats: (state) => {
      state.postTradesSplitArrPmValues = [];
      state.postTradeSplitArrValues = [];
    },
    setPostTradesSplitArrPmValues: (state, action) => {
      if (
        state.postTradesSplitArrPmValues.length === 0 ||
        state.postTradesSplitArrPmValues.filter(
          (arrObj) => arrObj.id === action.payload.id
        ).length === 0
      ) {
        const key = action.payload.key;
        const value = action.payload.value;
        const obj = { [`${key}`]: value, id: action.payload.id };
        state.postTradesSplitArrPmValues = [
          ...state.postTradesSplitArrPmValues,
          obj,
        ];
      } else if (
        state.postTradesSplitArrPmValues.filter(
          (arrObj) => arrObj.id === action.payload.id
        ).length !== 0
      ) {
        const arrCopy = [...state.postTradesSplitArrPmValues];
        const index = state.postTradesSplitArrPmValues.findIndex(
          (arrObj) => arrObj.id === action.payload.id
        );

        const value = action.payload.value;
        const keyOfState = action.payload.key;
        arrCopy[index][`${keyOfState}`] = value;
        state.postTradesSplitArrPmValues = arrCopy;
      }
    },
    setPostTradesSplitArrValues: (state, action) => {
      if (
        state.postTradeSplitArrValues.length === 0 ||
        state.postTradeSplitArrValues.filter(
          (arrObj) => arrObj.id === action.payload.id
        ).length === 0
      ) {
        const key = action.payload.key;
        const value = action.payload.value;
        const obj = { [`${key}`]: value, id: action.payload.id };
        state.postTradeSplitArrValues = [...state.postTradeSplitArrValues, obj];
      } else if (
        state.postTradeSplitArrValues.filter(
          (arrObj) => arrObj.id === action.payload.id
        ).length !== 0
      ) {
        const arrCopy = [...state.postTradeSplitArrValues];
        const index = state.postTradeSplitArrValues.findIndex(
          (arrObj) => arrObj.id === action.payload.id
        );

        const value = action.payload.value;
        const keyOfState = action.payload.key;
        arrCopy[index][`${keyOfState}`] = value;
        state.postTradeSplitArrValues = arrCopy;
      }
    },
    setSplitManner: (state, action) => {
      state.splitManner = action.payload;
    },
    setPostTradeSplitNumber: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state[`${key}`] = value;
    },
    setRfqObject: (state, action) => {
      state.rfqObject = action.payload;
    },
    getMarketMakerAndClientSuccess: (state, action) => {
      state.makorMarketMakers = action.payload[0].makorMarketMakers;
      state.makorClients = action.payload[1].makorClients;
      state.oscarGrussMarketMakers = action.payload[2].oscarGrussMarketMakers;
      state.oscarGrussClients = action.payload[3].oscarGrussClient;
      state.makorHSBCMarketMakers = action.payload[4].makorHSBCMarketMakers;
      state.makorHSBCClient = action.payload[5].makorHSBCClient;
      state.allMakorHSBCClientAndMarketMakers = [
        ...action.payload[4].makorHSBCMarketMakers,
        ...action.payload[5].makorHSBCClient,
      ];
    },
    setCurrentMarketMaker: (state, action) => {
      state.currentMarketMaker = action.payload;
    },
    setCurrentClient: (state, action) => {
      if (state.postTradeSplitArrValues.length > 0) {
        {
          state.currentClient = Array.isArray(action.payload)
            ? action.payload
            : [action.payload];
        }
      } else {
        if (state.currentClient.length > 0) {
          if (state.currentClient[0] !== action.payload) {
            let newState = state.currentClient;
            newState[0] = action.payload;
            state.currentClient = newState;
          } else {
            state.currentClient = state.currentClient;
          }
        } else {
          state.currentClient = [...state.currentClient, action.payload];
        }
      }
    },
    clearCurrentClients: (state, action) => {
      state.currentClient = [];
    },
    addMMToClients: (state, action) => {
      // state.currentClient = [...state.currentClient, action.payload];
      let indexOfMM;
      let newState;
      if (state.marketMakerNeedsConfo) {
        if (state.currentClient.length > 0) {
          indexOfMM = state.currentClient.findIndex((client) =>
            client?.includes("(MM)")
          );
          if (indexOfMM > -1) {
            if (state.currentClient[indexOfMM] !== action.payload) {
              newState = state.currentClient;
              newState.splice(indexOfMM, 1);
              newState = [...newState, action.payload];
              state.currentClient = newState;
            } else {
              return;
            }
          } else {
            state.currentClient = [...state.currentClient, action.payload];
          }
        } else {
          return;
        }
      } else {
        state.currentClient = state.currentClient.filter(
          (client) => client !== action.payload
        );
      }
    },
    setCurrentPb: (state, action) => {
      state.currentPb = action.payload;
    },
    setMarketMaker: (state, action) => {
      state.marketMaker = action.payload;
    },
    setTrader: (state, action) => {
      state.trader = action.payload;
    },
    setDfMids: (state, action) => {
      state.dfMids = action.payload;
    },
    setTraderNote: (state, action) => {
      state.traderNote = action.payload;
    },
    setMMNeedsConfo: (state, action) => {
      if (action.payload !== null && action.payload !== undefined) {
        state.marketMakerNeedsConfo = action.payload;
      } else {
        state.marketMakerNeedsConfo = !state.marketMakerNeedsConfo;
      }
    },
    setConfoInitial: (state, action) => {
      state.confoInitial = action.payload;
    },
    setToEmailAddresses: (state, action) => {
      state.toEmailAddresses = action.payload;
    },
    setCCEmailAddresses: (state, action) => {
      state.ccEmailAddresses = action.payload;
    },
    setSingleConfo: (state, action) => {
      if (action.payload !== null && action.payload !== undefined) {
        state.singleConfo = action.payload;
      } else {
        state.singleConfo = !state.singleConfo;
      }
      if (state.singleConfo === true) {
        if (state.marketMakerNeedsConfo) {
          state.currentClient = [
            state.currentClient[0],
            state.currentClient.find((client) => client?.includes("(MM)")),
          ];
        } else {
          state.currentClient = [state.currentClient[0]];
        }
      } else {
        let marketMaker = state.currentClient.find((client) =>
          client?.includes("(MM)")
        );
        state.currentClient = state.postTradeSplitArrValues.map(
          (split, index) =>
            `${split?.postTradeClientSplitId} (${alphabet[index]})`
        );
        if (state.marketMakerNeedsConfo) {
          state.currentClient = [...state.currentClient, marketMaker];
        } else {
          state.currentClient = [...state.currentClient];
        }
      }
    },
    removeSplitFromClients: (state, action) => {
      if (state.marketMakerNeedsConfo === true) {
        state.marketMaker = "";
        state.marketMakerNeedsConfo = false;
        state.currentClient = [];
      } else {
        state.currentClient = [];
      }
    },
    setPBA: (state, action) => {
      state.pba = action.payload;
    },
    setSD: (state, action) => {
      state.sd = action.payload;
    },
    setStartSendEmailStatus: (state, action) => {
      state.startSendEmailStatus = action.payload;
    },
  },
});

export const removeRfqFromPreValues = (rfq_id) => (dispatch, getState) => {
  const postTradeSplitArrPreValues =
    getState().rfqPostTradeSlice.postTradeSplitArrPreValues;
  const arr = [...postTradeSplitArrPreValues];
  const index = arr.findIndex((t) => t.rfq_id === rfq_id);
  if (arr.length === 1) {
    dispatch(setPostTradeSplitArrPreValues([]));
  } else {
    arr.splice(index, 1);
    dispatch(setPostTradeSplitArrPreValues(arr));
  }
};
export const resetPreSplitValues = (rfq_id) => (dispatch, getState) => {
  const postTradeSplitArrPreValues =
    getState().rfqPostTradeSlice.postTradeSplitArrPreValues;
  const arr = [...postTradeSplitArrPreValues];
  const index = arr.findIndex((t) => t.rfq_id === rfq_id);
  let obj = { ...arr[index] };
  obj = { ...obj, split_values: [] };
  arr.splice(index, 1, obj);
  dispatch(setPostTradeSplitArrPreValues(arr));
};

export const setPostTradeSplitArrPreValuesBookingViewThunk =
  (rfq_id, value) => (dispatch, getState) => {
    const postTradeSplitArrPreValues =
      getState().rfqPostTradeSlice.postTradeSplitArrPreValues;
    const arr = [...postTradeSplitArrPreValues];

    if (arr.filter((t) => t.rfq_id === rfq_id).length === 0) {
      arr.push({ rfq_id: rfq_id, client: value });
      dispatch(setPostTradeSplitArrPreValues(arr));
    } else {
      const index = arr.findIndex((t) => t.rfq_id === rfq_id);
      const arrObj = arr[index];
      let obj = { ...arrObj };
      const newSplitValues = obj.split_values.map((split_value) => {
        return { ...split_value, postTradeClientSplitId: value };
      });
      obj = { ...obj, client: value, split_values: newSplitValues };
      arr.splice(index, 1, obj);
      dispatch(setPostTradeSplitArrPreValues(arr));
    }
  };

export const setPostTradeSplitArrPreValuesThunk =
  (data) => (dispatch, getState) => {
    const postTradeSplitArrPreValues =
      getState().rfqPostTradeSlice.postTradeSplitArrPreValues;
    const arr = [...postTradeSplitArrPreValues];
    const key = data.key;
    const value = data.value;
    if (
      arr.length === 0 ||
      arr.filter((t) => t.rfq_id === data.rfq_id).length === 0
    ) {
      const obj = {
        [`${key}`]: value,
        rfq_id: data.rfq_id,
        split_values: [],
      };
      arr.push(obj);
      dispatch(setPostTradeSplitArrPreValues(arr));
    } else if (arr.filter((t) => t.rfq_id === data.rfq_id).length !== 0) {
      const index = arr.findIndex((t) => t.rfq_id === data.rfq_id);
      arr[index] = { ...arr[index], [`${key}`]: value };
      dispatch(setPostTradeSplitArrPreValues(arr));
    }
  };

export const setMMNeedsConfoAsync = (action) => (dispatch, getState) => {
  if (getState().rfqPostTradeSlice.marketMakerNeedsConfo === true) {
    dispatch(
      addMMToClients(`${getState().rfqPostTradeSlice?.marketMaker} (MM)`)
    );
  } else {
    dispatch(setMMNeedsConfo());
    dispatch(
      addMMToClients(`${getState().rfqPostTradeSlice?.marketMaker} (MM)`)
    );
  }
};
export const getMarketMakersClients = () => async (dispatch) => {
  const token = sessionStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}` +
        END_POINT.MARKET_MAKER +
        "/get_market_makers_and_clients",
      { headers: { Authorization: token } }
    );

    dispatch(getMarketMakerAndClientSuccess(response.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(clearLoginState());
      }
    }
  }
};
export const setClientsWhenSplited = () => (dispatch, getState) => {
  let clientsNameArr =
    getState().rfqPostTradeSlice?.postTradeSplitArrValues.map(
      (split, index) => `${split?.postTradeClientSplitId} (${alphabet[index]})`
    );
  if (getState().rfqPostTradeSlice?.singleConfo === true) {
    clientsNameArr = [clientsNameArr[0]];
  }
  let mmClient;
  // if(getState().rfqPostTradeSlice?.singleConfo){
  //   return
  // }else{
  if (getState().rfqPostTradeSlice?.marketMakerNeedsConfo) {
    mmClient = getState().rfqPostTradeSlice?.currentClient?.find((client) =>
      client?.includes("(MM)")
    );
    if (mmClient === undefined || mmClient === null) {
      clientsNameArr.push(`${getState().rfqPostTradeSlice?.marketMaker} (MM)`);
    } else {
      clientsNameArr.push(mmClient);
    }
  }
  // }

  dispatch(setCurrentClient(clientsNameArr));
  dispatch(
    setCurrentPb(
      getState().rfqPostTradeSlice?.postTradeSplitArrValues[0]
        ?.postTradePmSplitId
    )
  );
};
export const saveConfoOnDataBase =
  (
    clientIndex,
    rfqEmailObj,
    toEmailAddresses,
    ccEmailAddresses,
    traderComment,
    bottomComment
  ) =>
  async (dispatch, getState) => {
    let rfqEmailObjToSend = rfqEmailObj;

    rfqEmailObjToSend =
      traderComment !== ""
        ? { ...rfqEmailObjToSend, trader_comment: traderComment }
        : rfqEmailObjToSend;

    rfqEmailObjToSend = bottomComment
      ? { ...rfqEmailObjToSend, bottom_comment: bottomComment }
      : rfqEmailObjToSend;
    const token = sessionStorage.getItem("token");

    const {
      currentMarketMaker,
      currentClient,
      currentPb,
      marketMaker,
      trader,
      traderNote,
      dfMids,
      confoInitial,
      marketMakerNeedsConfo,
      postTradeSplitArrValues,
      singleConfo,
      sd,
    } = getState()?.rfqPostTradeSlice;

    // let postTradeData

    let postTradeData = {
      rfqId: sessionStorage.getItem("rfqId"),
      confo_initial: confoInitial,
      client_selection: /(?!.*\()(?=.*\))[A-Z]/g.test(currentClient[0])
        ? currentClient[0]
            .replace(/(?!.*\()(?=.*\))[A-Z]/g, "")
            .replace(" ()", "")
        : currentClient[0],
      df_mids: dfMids,
      market_maker_selection: marketMaker,
      pb: currentMarketMaker,
      pm_selection:
        postTradeSplitArrValues.length > 0 &&
        postTradeSplitArrValues.some(
          (p) => p.postTradeClientSplitId === currentClient[clientIndex]
        )
          ? postTradeSplitArrValues[clientIndex].postTradePmSplitId
          : currentPb,
      trader_note: traderNote,
      trader_selection: trader,
      email_body: rfqEmailObjToSend,
      mm_need_confo: marketMakerNeedsConfo ? 1 : 0,
      to_email: toEmailAddresses?.join(";"),
      cc_email: ccEmailAddresses?.join(";"),
      allocations: JSON.stringify(postTradeSplitArrValues),
      singleConfo: singleConfo,
      sent_to: currentClient[clientIndex],
    };
    if (currentMarketMaker === "MAKOR-RBS") {
      postTradeData = { ...postTradeData, SD: sd };
    }

    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}` + END_POINT.POST_TRADE,
        { postTradeData: postTradeData },
        { headers: { Authorization: token } }
      );

      const rfqsList = getState().rfqSlice.rfq;
      const arr = [...rfqsList];

      let rfqIndex = arr.findIndex((key) => {
        return Number(key.rfq_id) === Number(res.data.rfq_id);
      });

      let rfqObj = { ...rfqsList[rfqIndex] };
      rfqObj = { ...rfqObj, status: res.data.status };
      arr.splice(rfqIndex, 1, rfqObj);
      dispatch(getRfqSuccess(arr));
    } catch (error) {
      throw new Error(error.message);
      console.log(error);
    }
  };

export const setEmailAddressesAsync = (emailObj) => (dispatch) => {
  if (emailObj === null || emailObj === undefined) {
    dispatch(setToEmailAddresses([]));
    dispatch(setCCEmailAddresses([]));
  } else {
    dispatch(setToEmailAddresses(emailObj.to));
    dispatch(setCCEmailAddresses(emailObj.cc));
  }
};

export const setAllValuesIfConfosExist =
  (emailFromDb) => (dispatch, getState) => {
    if (emailFromDb !== undefined) {
      dispatch(setThereIsConfo(true));
      if (emailFromDb?.allocations.length > 0) {
        dispatch(setPostTradeSplitNumber(emailFromDb?.allocations.length));
        emailFromDb?.allocations?.map((split, index) => {
          const clientObj = {
            value: split.postTradeClientSplitId,
            key: "postTradeClientSplitId",
            id: index,
          };
          const pmObj = {
            value: split.postTradePmSplitId
              ? split.postTradePmSplitId
              : "no options",
            key: "postTradePmSplitId",
            id: index,
          };
          if (split.hasOwnProperty(`Leg1NotionalSplitOption`)) {
            dispatch(setSplitManner("notional"));
            for (let key in split) {
              if (key !== "id") {
                const optionObj = {
                  value: split[key],
                  key: key,
                  id: index,
                };
                dispatch(setPostTradesSplitArrValues(optionObj));
              }
            }
          } else {
            dispatch(setSplitManner("percentage"));
            const optionObj = {
              value: split.postTradeSplitOption,
              key: "postTradeSplitOption",
              id: index,
            };
            dispatch(setPostTradesSplitArrValues(optionObj));
          }

          dispatch(setPostTradesSplitArrValues(pmObj));
          dispatch(setPostTradesSplitArrValues(clientObj));
        });
        dispatch(setSingleConfo(emailFromDb?.singleConfo === 0 ? false : true));
        dispatch(setClientsWhenSplited());
      } else {
        dispatch(setCurrentClient(emailFromDb?.client_selection));
      }
      dispatch(setConfoInitial(emailFromDb?.confo_initial));
      dispatch(setDfMids(emailFromDb?.df_mids));
      dispatch(
        setMarketMaker(
          emailFromDb?.market_maker_selection !== ""
            ? emailFromDb?.market_maker_selection
            : null
        )
      );
      dispatch(setCurrentMarketMaker(emailFromDb?.pb));
      dispatch(setCurrentPb(emailFromDb?.pm_selection));
      dispatch(setTraderNote(emailFromDb?.trader_note));
      dispatch(setTrader(emailFromDb?.trader_selection));
      dispatch(setToEmailAddresses(emailFromDb?.to_email?.split(";")));
      dispatch(setCCEmailAddresses(emailFromDb?.cc_email?.split(";")));
      dispatch(
        setMMNeedsConfo(emailFromDb?.mm_need_confo === 1 ? true : false)
      );
      if (emailFromDb?.mm_need_confo === 1) {
        let mmClient = `${emailFromDb?.market_maker_selection} (MM)`;
        dispatch(addMMToClients(mmClient));
      }
      dispatch(setSD(emailFromDb?.sd === 1 ? true : false));
    } else {
      dispatch(setThereIsConfo(false));
    }
  };

export const ResetPostTradePanelForRfqWorkSpace =
  () => (dispatch, getState) => {
    const postTradeSplitArrValues =
      getState().rfqPostTradeSlice.postTradeSplitArrValues;
    const rfqObject = getState().rfqPostTradeSlice.rfqObject;
    if (postTradeSplitArrValues.length > 0) {
      dispatch(
        actionSnackBar.setSnackBar(
          "success",
          `Split data have been reset`,
          5000
        )
      );
      dispatch(resetPreSplitValues(rfqObject.id));
      const splitNumberObj = {
        value: 2,
        key: "postTradeSplitNumber",
      };
      dispatch(setIsSplitted(false));
      dispatch(setPostTradeSplitNumber(splitNumberObj));
      dispatch(resetPostTradeStats());
      dispatch(clearCurrentClients());
      dispatch(setCurrentPb(""));
      dispatch(setMarketMaker(""));
      dispatch(setTrader(""));
      dispatch(setMMNeedsConfo(false));
      dispatch(setPBA(false));
      dispatch(setCurrentMarketMaker(""));
      dispatch(setMMNeedsConfoAsync());
    }
  };

export const {
  setThereIsConfo,
  setRfqObject,
  getMarketMakerAndClientSuccess,
  setCurrentMarketMaker,
  setCurrentClient,
  clearCurrentClients,
  setMarketMaker,
  setTrader,
  setCurrentPb,
  setDfMids,
  setConfoInitial,
  setTraderNote,
  setMMNeedsConfo,
  addMMToClients,
  removeSplitFromClients,
  setSplitManner,
  setIsSplitted,
  setPostTradeSplitNumber,
  setPostTradesSplitArrValues,
  setPostTradesSplitArrPmValues,
  resetPostTradeStats,
  setCurrentLegsNotional,
  setCurrentDeltasNotional,
  setCurrentNearNotional,
  setCurrentFarNotional,
  setPostTradeAmountLeftToSplit,
  postTradeNearNotionalLeftToSplit,
  postTradeFarNotionalLeftToSplit,
  setPostTradeAmountLeftToNotionalSplit,
  postTradeAmountLeftToDeltaNotionalSplit,
  setToEmailAddresses,
  setCCEmailAddresses,
  setSingleConfo,
  setPostTradeSplitArrPreValues,
  resetPotTradeSplitArrPreValues,
  setSD,
  setPBA,
  setStartSendEmailStatus,
} = rfqPostTradeSlice.actions;
export default rfqPostTradeSlice.reducer;
