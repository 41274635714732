function bookToETG(etgBookingRespone, hedgeType, dealType) {
  console.log('dealType: ', dealType)
  let bookingStatus = "";
  let etgMessages;
  if (etgBookingRespone?.data?.options?.toString().includes("DOCTYPE ")) {
    bookingStatus = "Failed";
  } else if (dealType === "SWAP") {
    if (etgBookingRespone?.data?.options?.length > 0) {
      etgMessages = etgBookingRespone?.data?.options?.toString();
      if (etgMessages.includes("Failed")) {
        bookingStatus = "Failed";
      } else {
        bookingStatus = "";
      }
    }
  } else if (hedgeType === "LIVE") {
    if (etgBookingRespone?.data?.hedges.length > 0) {
      bookingStatus = "Failed";
    } else {
      if (etgBookingRespone?.data?.options?.length > 0) {
        etgMessages = etgBookingRespone?.data?.options?.toString();
        if (etgMessages.includes("Failed")) {
          bookingStatus = "Failed";
        } else {
          bookingStatus = "";
        }
      } else {
        bookingStatus = "Failed";
      }
    }
  } else if (hedgeType === "FWD" || hedgeType === "SPOT") {
    if (etgBookingRespone?.data?.options?.length > 0) {
      if (etgBookingRespone?.data?.options?.toString().includes("Failed")) {
        bookingStatus = "Failed";
      } else if (etgBookingRespone?.data?.hedges?.length > 0) {
        if (etgBookingRespone?.data?.hedges?.toString().includes("Failed")) {
          bookingStatus = "Failed";
        } else {
          bookingStatus = "";
        }
      } else {
        bookingStatus = "Failed";
      }
    } else {
      bookingStatus = "Failed";
    }
  }

  return bookingStatus;
}

export { bookToETG };
